import {
  InMemoryCache,
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  ServerError,
  fromPromise,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { StoreObject } from '@apollo/client/utilities/graphql/storeUtils';

import { clearToken, getAuthorizationHeader, getRefreshToken } from './token';
import { refresh } from '../api/Login/login';
import { uniqBy } from 'lodash';
import { mergeAllWatcherEvents } from '../routes/WatcherEvents/clientMerge';

const httpLink = createHttpLink({
  uri: ({ operationName }) =>
    `${process.env.REACT_APP_HOPP_API_URL}/api?op=${operationName}`,
});

const refreshLink = onError(({ networkError, forward, operation }) => {
  if (networkError && (networkError as ServerError).statusCode === 401) {
    const refreshToken = getRefreshToken();
    if (refreshToken) {
      return fromPromise(
        refresh(refreshToken).catch(() => {
          clearToken();
          window.location.reload();
        }),
      ).flatMap(() => {
        // retry the request, returning the new observable
        return forward(operation);
      });
    }
  }
});

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: getAuthorizationHeader(),
    },
  });
  return forward(operation);
});

const link = from([refreshLink, authLink, httpLink]);

const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          tripsByDates: {
            keyArgs: ['fleetId', 'unit'],
            merge: (existing, incoming, { readField }) => {
              const dates = existing ? { ...existing.dates } : {};
              incoming.dates.forEach((date: StoreObject) => {
                dates[readField('date', date) as string] = date;
              });
              return {
                pageInfo: incoming.pageInfo,
                dates,
              };
            },
            read: existing =>
              existing && { ...existing, dates: Object.values(existing.dates) },
          },
          allIssues: {
            keyArgs: ['fleetId', 'vehicleId'],
            merge(existing = { issues: [] }, incoming: any) {
              return {
                issues: [...existing.issues, ...incoming.issues],
                pageInfo: incoming.pageInfo,
              };
            },
            read: existing =>
              existing && {
                ...existing,
                issues: Object.values(existing.issues),
              },
          },
          organizations: {
            keyArgs: ['fleetId', 'sortColumn', 'sortOrder', 'search'],
            merge(existing = { organizations: [] }, incoming: any) {
              return {
                organizations: [
                  ...existing.organizations,
                  ...incoming.organizations,
                ],
                pageInfo: incoming.pageInfo,
              };
            },
          },
          allWatcherEvents: mergeAllWatcherEvents,
          allFinishedTrips: {
            keyArgs: ['fleetId', 'userId', 'vehicleId', 'cardIdForPan'],
            merge: (existing = { trips: [] }, incoming: any) => {
              return {
                trips: [...existing.trips, ...incoming.trips],
                pageInfo: incoming.pageInfo,
              };
            },
            read: existing =>
              existing && {
                ...existing,
                trips: Object.values(existing.trips),
              },
          },
          allStaffShifts: {
            keyArgs: ['fleetId', 'startDate', 'endDate'],
            merge: (existing = { staffShifts: [] }, incoming) => {
              return {
                staffShifts: [...existing.staffShifts, ...incoming.staffShifts],
                pageInfo: incoming.pageInfo,
              };
            },
            read: existing =>
              existing && {
                ...existing,
                staffShifts: Object.values(existing.staffShifts),
              },
          },
          vehicleEvents: {
            keyArgs: ['vehicleId'],
            merge(existing = { events: [] }, incoming: any) {
              return {
                events: [...existing.events, ...incoming.events],
                pageInfo: incoming.pageInfo,
              };
            },
            read: existing =>
              existing && {
                ...existing,
                events: Object.values(existing.events),
              },
          },
          allTaxiDrivers: {
            keyArgs: ['isPending', 'isApproved'],
            merge(existing = { edges: [] }, incoming: any, options) {
              return {
                ...incoming,
                edges: uniqBy([...existing.edges, ...incoming.edges], 'cursor'),
              };
            },
            read: existing => existing,
          },
          couponGroups: {
            keyArgs: ['fleetId', 'excludeNotIssuedByUser'],
            merge(existing = { couponGroups: [] }, incoming: any) {
              return {
                couponGroups: [
                  ...existing.couponGroups,
                  ...incoming.couponGroups,
                ],
                pageInfo: incoming.pageInfo,
              };
            },
          },
          paginatedCoupons: {
            keyArgs: ['fleetId', 'couponGroupId'],
            merge(existing = { coupons: [] }, incoming: any) {
              return {
                coupons: [...existing.coupons, ...incoming.coupons],
                pageInfo: incoming.pageInfo,
              };
            },
          },
        },
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export default client;
